import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="photoviewer"
export default class extends Controller {
  static targets = ["bigviewer", "bigimage"]

  connect() {
  }

  show(event) {
    // this.bigviewerTarget.textContent = event.target.dataset['name'];
    this.bigimageTarget.src = `/photos/${event.target.dataset['name']}`;
    this.bigviewerTarget.classList.remove('hidden');
    this.bigviewerTarget.classList.add('shown');
  }

  hide(event) {
    this.bigviewerTarget.classList.remove('shown');
    this.bigviewerTarget.classList.add('hidden');
  }
}
